import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  select: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '4px',
      border: '1.5px solid rgba(0, 39, 77, 0.15)'
    },
    '& legend': {
      maxWidth: '0px'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 39, 77, 0.15)'
    },
    '& .MuiSelect-select': {
      paddingTop: '26px',
      fontWeight: 400
    },
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderRadius: '4px',
      border: '1.5px solid rgba(0, 39, 77, 0.15)'
    }
  },
  selectMenu: {
    fontSize: '14px'
  },
  selectLabel: {
    paddingTop: '18px'
  },
  textField: {
    borderRadius: '4px',
    border: '1.5px solid rgba(0, 39, 77, 0.15)',
    padding: '6px 16px'
  },
  inputLabel: {
    color: '#54708C',
    padding: '6px 16px'
  },
  errorText: {
    color: 'rgba(204, 8, 1, 1)',
    fontSize: '12px',
    paddingLeft: '16px',
    position: 'absolute'
  },
  errorField: {
    borderColor: 'rgba(204, 8, 1, 1)'
  }
}))
